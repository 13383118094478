import React, { Fragment, useEffect, useState } from "react"
import HailCXLayout from "../../components/layout/hailcx-layout"
import HailCXRightImageHeader from "../../components/layout/header/hailcx-right-image-header"
import HailCXSingleButtonFooter from "../../components/layout/footer/hailcx-single-button-footer"
import {
  EuiDescriptionList,
  EuiImage,
  EuiPanel,
  EuiSpacer,
  EuiText,
} from "@elastic/eui"
import Images from "../../images"
import { navigate } from "gatsby-link"
import { useStore } from "../../stores"
import { ProcessStates, RepairTypes } from "../../entities/constants"
import { observer } from "mobx-react-lite"
import SummaryItem from "../../entities/summaryItems"
import "../../styles/hailcx-description-lists.css"
import agent from "../../api/agent"
import HailCXRepairTypeAlert from "../../components/hailcx-repairtype-alert"
import HailCXHubWrapper from "../../components/hailcx-hubwrapper"
import SummaryRepairLocation from "../../components/booking/summary-repairlocation"

const ReadyToBookPage = () => {
  const [repairSummary, setRepairSummary] = useState<SummaryItem[]>([])
  const { customerStore, facilityStore } = useStore()
  const { customer, setCustomer } = customerStore
  const { facility } = facilityStore

  const data = {
    title: "Summary",
    buttonContent: "Select a booking time",
  }

  const handleClick = () => {
    console.log("Navigate to select booking slot.")

    navigate("/book/booking-selection")
  }

  useEffect(() => {
    customerStore.saveProcessState(ProcessStates[1].Id, ProcessStates[1].Name)

    if (customer) {
      const repairType = RepairTypes.find(
        rt => rt.acronym === customer.repairTypeQuoted
      )

      if (
        customer.repairTypeQuoted == RepairTypes[3].acronym ||
        customer.repairTypeQuoted == RepairTypes[4].acronym
      ) {
        navigate("/book/booking-notapplicable")
        return
      }

      if (repairType) {
        const summary = [
          {
            title: <EuiImage src={Images.Clipboard} alt="Repair Type" />,
            description: repairType?.getUIDetails(),
          },
          {
            title: <EuiImage src={Images.MapMarker} alt="Location" />,
            description: (
              <EuiText grow={false}>
                <SummaryRepairLocation facility={facility} />
              </EuiText>
            ),
          },
          {
            title: <EuiImage src={Images.CarRepair} alt="Description" />,
            description: repairType?.getUIDescription(),
          },
        ]

        setRepairSummary([...summary])
      }
    }
  }, [customer?.repairTypeQuoted])

  return (
    <HailCXHubWrapper>
      <HailCXLayout
        header={
          <HailCXRightImageHeader
            title={data.title}
            progressValue={45}
            vehicleInfo={customerStore.getVehicleInfo()}
          />
        }
        footer={
          <HailCXSingleButtonFooter
            buttonContent={data.buttonContent}
            isLoading={false}
            handleClick={handleClick}
          />
        }
      >
        <EuiText
          grow={false}
          className="eui-textLeft"
          style={{ marginRight: "auto", marginLeft: "auto" }}
        >
          <h1>Ready to book!</h1>
        </EuiText>
        <EuiSpacer />
        <EuiPanel>
          <EuiDescriptionList type="column" listItems={repairSummary} />
        </EuiPanel>
        <HailCXRepairTypeAlert />
      </HailCXLayout>
    </HailCXHubWrapper>
  )
}

export default observer(ReadyToBookPage)
